* {
    margin: 0;
    padding: 0;
    font-family: Arial, "gilroy-light", Helvetica, "gilroy", sans-serif;
    text-decoration: none;
    color: #3e3e3e;
    text-align: center;
  }
  

  body {
    background-color: #f4f5f6;
    border-radius: 25px;
height: 100%;
  }

  .block {

    opacity: 0;
    transform: translateY(50px);
    transition: transform 1s, opacity 1s;
}
  /* //////////////////////// LAZY LOAD /////////////////////////////////////////////////////////// */
  
  .lazy-img {
    filter: blur(20px);
    min-height: 138px;
    min-width: 220px;
  }
  
  h3 {
    font-weight: lighter;
  }


    /* //////////////////////// FONTS /////////////////////////////////////////////////////////// */

  @font-face {
    font-family: gilroy-extrabold;
    src: url("../fonts/gilroy/Gilroy-ExtraBold.otf");
  }
  
  @font-face {
    font-family: gilroy-light;
    src: url("../fonts/gilroy/Gilroy-Light.otf");
  }
  
  @font-face {
    font-family: open-sans-light;
    src: url("../fonts/opensans/OpenSans-Light.ttf");
  }
  
  @font-face {
    font-family: open-sans-bold;
    src: url("../fonts/opensans/OpenSans-Bold.ttf");
  }
  
  @font-face {
    font-family: open-sans-semibold;
    src: url("../fonts/opensans/OpenSans-Semibold.ttf");
  }
  
  