* {
  color: #3e3e3e;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: Arial, gilroy-light, Helvetica, gilroy, sans-serif;
  text-decoration: none;
}

body {
  background-color: #f4f5f6;
  border-radius: 25px;
  height: 100%;
}

.block {
  opacity: 0;
  transition: transform 1s, opacity 1s;
  transform: translateY(50px);
}

.lazy-img {
  filter: blur(20px);
  min-width: 220px;
  min-height: 138px;
}

h3 {
  font-weight: lighter;
}

@font-face {
  font-family: gilroy-extrabold;
  src: url("Gilroy-ExtraBold.93f97143.otf");
}

@font-face {
  font-family: gilroy-light;
  src: url("Gilroy-Light.9d6c3395.otf");
}

@font-face {
  font-family: open-sans-light;
  src: url("OpenSans-Light.b716425d.ttf");
}

@font-face {
  font-family: open-sans-bold;
  src: url("OpenSans-Bold.dbf352ea.ttf");
}

@font-face {
  font-family: open-sans-semibold;
  src: url("OpenSans-Semibold.dced3720.ttf");
}

/*# sourceMappingURL=index.624b4926.css.map */
